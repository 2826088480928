const firebaseConfig = {
  apiKey: "AIzaSyBmKKC5vu4wyfevJqkAmYwKDP08CMwfdho",
  authDomain: "just-for-java.firebaseapp.com",
  databaseURL: "https://just-for-java.firebaseio.com",
  projectId: "just-for-java",
  storageBucket: "just-for-java.appspot.com",
  messagingSenderId: "964585152553",
  appId: "1:964585152553:web:024448a188553cc6ff3c38",
  measurementId: "G-5FLHJMDSZZ"
};

export default firebaseConfig;
